<template>
  <van-tabbar route v-show="showTabber">
    <van-tabbar-item to="/" v-if="false" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item to="/category" v-if="false" icon="points">分类</van-tabbar-item>
    <van-tabbar-item to="/" icon="points">分类</van-tabbar-item>
    <van-tabbar-item to="/ware/shoppingcart" icon="shopping-cart-o" :dot="showCartDot">
      购物车
    </van-tabbar-item>
    <van-tabbar-item to="/mine" icon="user-circle-o">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { onMounted, watch, ref } from 'vue'
import { blackListOfTabbar } from '@/config/constInfo'
import { useStore } from 'vuex'
export default {
  name: 'tabbarNormal',
  setup() {
    const store = useStore()
    const showTabber = ref(true)
    const showCartDot = ref(false)
    watch(
      () => store.state.currentpath,
      (newValue) => {
        if (blackListOfTabbar.indexOf(newValue) >= 0) {
          showTabber.value = false
        } else {
          showTabber.value = true
        }
      }
    )
    watch(
      () => store.state.shoppingCartList,
      (newValue) => {
        showCartDot.value = newValue.length > 0
      }
    )

    onMounted(() => {})
    return {
      showTabber,
      showCartDot,
    }
  },
}
</script>

<style lang="less" scoped></style>
